/* ----- Base Theme Setup ----- */
:root {
  /* Base Theme Colors */
  --color-primary: #3b82f6;
  --color-secondary: #a855f7;
  --color-accent: #0ea5e9;
  --color-my-blue: var(--color-primary);
  
  /* Derived Color Variations */
  --color-primary-light: rgba(59, 130, 246, 0.2);
  --color-primary-dark: #2563eb;
  --color-secondary-light: rgba(168, 85, 247, 0.2);
  --color-secondary-dark: #7e22ce;
  
  /* Color RGB values for animations */
  --color-primary-rgb: 59, 130, 246;
  --color-secondary-rgb: 168, 85, 247;
  
  /* Gradients */
  --gradient-primary: linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  --gradient-background: linear-gradient(135deg, var(--color-primary-light) 0%, var(--color-secondary-light) 100%);
  
  /* UI Element Styles */
  --button-radius: rounded-md;
  --card-radius: rounded-xl;
  --border-style: border-solid;
  
  /* Animation Settings */
  --animation-speed: 0.5s;
  --animation-scale: 1.05;
  
  /* Typography */
  --font-heading: 'Figtree', sans-serif;
  --font-body: 'Figtree', sans-serif;
  
  /* Shadow Styles */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Dark Mode Overrides */
.dark {
  --color-primary-light: rgba(59, 130, 246, 0.15);
  --color-secondary-light: rgba(168, 85, 247, 0.15);
  
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.3);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.2);
}

/* ----- Original App Styles ----- */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----- Apply Theme Colors to Elements ----- */
.text-my-blue {
  color: var(--color-primary);
}

.bg-my-blue {
  background-color: var(--color-primary);
}

.border-my-blue {
  border-color: var(--color-primary);
}

.hover\:text-my-blue:hover {
  color: var(--color-primary);
}

.hover\:bg-my-blue:hover {
  background-color: var(--color-primary);
}

.hover\:border-my-blue:hover {
  border-color: var(--color-primary);
}

.ring-my-blue {
  --tw-ring-color: var(--color-primary);
}

.focus\:ring-my-blue:focus {
  --tw-ring-color: var(--color-primary);
}

/* Gradient Backgrounds */
.bg-gradient-primary {
  background: var(--gradient-primary);
}

.from-primary {
  --tw-gradient-from: var(--color-primary);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0));
}

.to-secondary {
  --tw-gradient-to: var(--color-secondary);
}

/* ----- Basic Animations ----- */
.animate-slidein {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.animate-slidein.opacity-0 {
  opacity: 0 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.8s ease forwards;
}

.animate-on-scroll, .skills-animate-on-scroll, .project-animate {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.animate-on-scroll.animate-in, .skills-animate-on-scroll.animate-fade-in, .project-animate.animate-fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* ----- Enhanced Hover Animations ----- */
.element-hover-animation {
  transition: transform var(--animation-speed) ease, 
              box-shadow var(--animation-speed) ease,
              opacity var(--animation-speed) ease;
}

.element-hover-animation:hover {
  transform: scale(var(--animation-scale));
  box-shadow: var(--shadow-lg);
}

/* Animated Background Areas */
.animated-gradient-bg {
  background-size: 200% 200%;
  background-image: var(--gradient-primary);
  animation: gradient-shift 15s ease infinite;
}

@keyframes gradient-shift {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

/* ----- Glassmorphism Effects ----- */
.glass-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.dark .glass-card {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* ----- Hero Section Enhancements ----- */
.enhanced-hero {
  position: relative;
  overflow: hidden;
}

.enhanced-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-background);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  z-index: -1;
}

/* ----- UI Mode Styles ----- */
/* UI Mode: Standard */
.ui-standard button {
  border-radius: 0.375rem;
  transition: all 0.3s ease;
}

.ui-standard .card {
  border-radius: 0.75rem;
  transition: all 0.3s ease;
}

/* UI Mode: Playful */
.ui-playful button {
  border-radius: 1rem;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.ui-playful .card {
  border-radius: 1.5rem;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.ui-playful h1, .ui-playful h2, .ui-playful h3 {
  letter-spacing: -0.025em;
}

.ui-playful button:hover, .ui-playful a:hover {
  transform: translateY(-3px) scale(1.03);
}

/* UI Mode: Minimal */
.ui-minimal button {
  border-radius: 0.125rem;
  transition: all 0.2s ease;
}

.ui-minimal .card {
  border-radius: 0.25rem;
  transition: all 0.2s ease;
}

.ui-minimal h1, .ui-minimal h2, .ui-minimal h3 {
  font-weight: 400;
  letter-spacing: 0.025em;
}

/* UI Mode: Glassmorphism */
.ui-glass .card,
.ui-glass .header,
.ui-glass .footer,
.ui-glass .dropdown {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.dark.ui-glass .card,
.dark.ui-glass .header,
.dark.ui-glass .footer,
.dark.ui-glass .dropdown {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* ----- Special Effects ----- */
/* Sparkle Effect for Elements */
.sparkle-elements .card:hover::after,
.sparkle-elements button:hover::after,
.sparkle-elements a:hover::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 
    0 0 10px 2px rgba(255, 255, 255, 0.8),
    0 0 20px 6px var(--color-primary),
    0 0 30px 12px var(--color-secondary);
  animation: sparkle 1.5s ease-in-out infinite;
  z-index: -1;
}

.sparkle-elements .card,
.sparkle-elements button,
.sparkle-elements a {
  position: relative;
  overflow: hidden;
}

@keyframes sparkle {
  0%, 100% { opacity: 0; transform: scale(0); }
  20% { opacity: 1; transform: scale(1); }
  80% { opacity: 0; transform: scale(3); }
}

/* ----- Text Effects ----- */
/* Gradient Text */
.gradient-text {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Glitch Text Effect */
.glitch-text {
  position: relative;
  animation: glitch 3s infinite;
}

@keyframes glitch {
  0% { transform: translate(0); }
  1% { transform: translate(-3px, 1px); }
  2% { transform: translate(3px, -1px); }
  3% { transform: translate(0); }
  100% { transform: translate(0); }
}

/* ----- Button Styles ----- */
.btn-primary {
  background: var(--gradient-primary);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: var(--button-radius);
  transition: all var(--animation-speed) ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.6s ease;
  z-index: -1;
}

.btn-primary:hover::before {
  left: 100%;
}

/* ----- Card Enhancements ----- */
.enhanced-card {
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-md);
  transition: all var(--animation-speed) ease;
  border-style: var(--border-style);
  overflow: hidden;
}

.enhanced-card:hover {
  transform: translateY(-5px) scale(var(--animation-scale));
  box-shadow: var(--shadow-xl);
}

/* ----- Background Patterns ----- */
.pattern-dots {
  background-color: var(--color-primary-light);
  background-image: radial-gradient(var(--color-secondary-light) 1px, transparent 1px);
  background-size: 20px 20px;
}

.pattern-grid {
  background-color: var(--color-primary-light);
  background-image: 
    linear-gradient(var(--color-secondary-light) 1px, transparent 1px),
    linear-gradient(90deg, var(--color-secondary-light) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* ----- 3D Effects ----- */
/* Floating 3D Effect for Cards */
.floating-card {
  transform-style: preserve-3d;
  perspective: 1000px;
}

.floating-card-inner {
  transition: transform 0.5s;
  transform: translateZ(0);
}

.floating-card:hover .floating-card-inner {
  transform: translateZ(20px);
}

/* ----- Cursor Effects ----- */
/* Custom cursor effect */
.custom-cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  pointer-events: none;
  background: var(--color-primary);
  opacity: 0.3;
  mix-blend-mode: difference;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease, background-color 0.3s ease, opacity 0.3s ease;
  z-index: 9999;
}

.custom-cursor-ring {
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
  border: 2px solid var(--color-primary);
  opacity: 0.2;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease, border-color 0.3s ease, transform 0.2s ease, opacity 0.3s ease;
  z-index: 9998;
}

/* Mouse trail animation */
@keyframes trail-fade {
  0% { opacity: 0.7; transform: translate(-50%, -50%) scale(1); }
  100% { opacity: 0; transform: translate(-50%, -50%) scale(3); }
}

.mouse-trail {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--color-primary);
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: trail-fade 1s forwards;
  z-index: 9997;
}

/* ----- Interactive Image Tilt Effect ----- */
.tilt-effect {
  transition: transform 0.3s ease;
  transform-style: preserve-3d;
}

.tilt-effect:hover {
  transform: perspective(500px) rotateX(var(--tilt-x, 0deg)) rotateY(var(--tilt-y, 0deg));
}

/* ----- Responsive Text Sizes ----- */
.adaptive-text {
  font-size: clamp(1rem, 4vw, 3rem);
  line-height: 1.2;
}

/* ----- Neon Text Effect ----- */
.neon-text {
  color: #fff;
  text-shadow:
    0 0 5px var(--color-primary),
    0 0 10px var(--color-primary),
    0 0 20px var(--color-primary),
    0 0 40px var(--color-secondary),
    0 0 80px var(--color-secondary);
}

/* ----- Reveal Animations ----- */
/* Animated Image Reveal */
.image-reveal {
  position: relative;
  overflow: hidden;
}

.image-reveal img {
  transform: scale(1.2);
  transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.image-reveal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  transform-origin: left;
  transform: scaleX(1);
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.image-reveal.revealed img {
  transform: scale(1);
}

.image-reveal.revealed::after {
  transform: scaleX(0);
}

/* ----- Text Animations ----- */
/* Animated Letter Effect */
.animate-letters {
  display: inline-block;
}

.animate-letter {
  opacity: 0;
  transform: translateY(20px);
}

.animate-letters.visible .animate-letter {
  opacity: 1;
  transform: translateY(0);
}

.animate-letter-fade {
  transition: opacity 0.6s, transform 0.6s;
}

.animate-letter-bounce {
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.6s;
}

.animate-letters.visible .animate-letter-bounce {
  transform: translateY(0) scale(1);
}

.animate-letter-slide {
  transform: translateX(-20px) translateY(0);
  transition: transform 0.6s, opacity 0.6s;
}

.animate-letters.visible .animate-letter-slide {
  transform: translateX(0) translateY(0);
}

.animate-letter-glitch {
  text-shadow: 0 0 0 transparent;
  animation-duration: 0.01s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(1);
}

.animate-letters.visible .animate-letter-glitch {
  animation-name: glitch-text;
}

@keyframes glitch-text {
  0% {
    opacity: 1;
    transform: translate(0) skew(0);
    text-shadow: 0 0 0 transparent;
  }
  2% {
    opacity: 1;
    transform: translate(-3px, 1px) skew(0.5deg);
    text-shadow: -3px 1px 0 var(--color-primary-light);
  }
  4% {
    opacity: 1;
    transform: translate(3px, -1px) skew(-0.5deg);
    text-shadow: 3px -1px 0 var(--color-secondary-light);
  }
  5% {
    opacity: 1;
    transform: translate(0) skew(0);
    text-shadow: 0 0 0 transparent;
  }
  100% {
    opacity: 1;
    transform: translate(0) skew(0);
    text-shadow: 0 0 0 transparent;
  }
}

.animate-letter-typewriter {
  opacity: 0;
  transform: translateX(0);
  position: relative;
}

.animate-letter-typewriter::after {
  content: '';
  position: absolute;
  right: -2px;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: var(--color-primary);
  animation: cursor-blink 1s steps(2) infinite;
}

.animate-letters.visible .animate-letter-typewriter {
  opacity: 1;
}

@keyframes cursor-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* ----- Background Effects ----- */
/* Particles Background */
.particles-bg {
  position: relative;
  overflow: hidden;
}

.particles-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(var(--color-primary-light) 1px, transparent 1px),
    radial-gradient(var(--color-secondary-light) 1px, transparent 1px);
  background-size: 40px 40px;
  background-position: 0 0, 20px 20px;
  z-index: -1;
  opacity: 0.5;
}

/* ----- Border Animations ----- */
/* Magic Border Animation */
.magic-border {
  position: relative;
}

.magic-border::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background: linear-gradient(90deg, 
    var(--color-primary), 
    var(--color-secondary), 
    var(--color-primary));
  border-radius: inherit;
  z-index: -1;
  background-size: 200% 200%;
  animation: border-shift 3s linear infinite;
}

@keyframes border-shift {
  0% { background-position: 0% 50%; }
  100% { background-position: 200% 50%; }
}

/* ----- Progress Indicators ----- */
/* Scroll Progress Bar */
.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 4px;
  background: var(--gradient-primary);
  z-index: 100;
  transition: width 0.1s;
}

/* ----- Link Effects ----- */
/* Animated Underline for Links */
.animated-underline {
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.animated-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--color-primary);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-out;
}

.animated-underline:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* ----- Interactive Button Effects ----- */
/* Liquid button effect */
.liquid-button {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.liquid-button::before {
  content: '';
  position: absolute;
  top: var(--y, 50%);
  left: var(--x, 50%);
  width: 0px;
  height: 0px;
  background: var(--color-primary-light);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s, height 0.6s;
  z-index: -1;
}

.liquid-button:hover::before {
  width: 300px;
  height: 300px;
}

/* ----- Pulse Animation ----- */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--color-primary-rgb), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0);
  }
}

.pulse-animation {
  animation: pulse 2s infinite;
}

/* ----- Custom Scrollbar Styles ----- */
/* Improved Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dark ::-webkit-scrollbar-track {
  background: #2d3748;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dark ::-webkit-scrollbar-thumb {
  background: #4a5568;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dark ::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

/* UI Mode Specific Scrollbars */
.ui-playful::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.ui-playful::-webkit-scrollbar-track {
  background: var(--color-primary-light);
  border-radius: 10px;
}

.ui-playful::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
  border: 3px solid var(--color-primary-light);
}

.ui-minimal::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.ui-minimal::-webkit-scrollbar-track {
  background: transparent;
}

.ui-minimal::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 0;
}

/* ----- Accessibility Enhancements ----- */
/* Mobile-Friendly Focus States */
button:focus, a:focus, input:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

@media (max-width: 768px) {
  button:focus, a:focus, input:focus {
    outline: none; /* Remove default focus on mobile for cleaner look */
  }
}

/* Modern focus-visible styles */
:focus-visible {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
  border-radius: 0.25rem;
}

/* ----- Utility Classes ----- */
/* Line Clamp */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Hide scrollbar for clean look on touch devices */
@media (pointer: coarse) {
  ::-webkit-scrollbar {
    display: none;
  }
  
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* Mobile-friendly touch targets */
@media (max-width: 768px) {
  button, a, input[type="button"], input[type="submit"] {
    min-height: 44px;
    min-width: 44px;
  }
}

/* Responsive Typography */
@media (max-width: 640px) {
  h1 {
    font-size: 2rem !important;
  }
  h2 {
    font-size: 1.5rem !important;
  }
}

/* Flow gradient animation */
.flowing-gradient {
  background: linear-gradient(
    270deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-accent),
    var(--color-secondary),
    var(--color-primary)
  );
  background-size: 300% 100%;
  animation: flowing-gradient-animation 10s ease infinite;
}

@keyframes flowing-gradient-animation {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

/* Floating Animation for Elements */
.floating {
  animation: floating-animation 3s ease-in-out infinite;
}

@keyframes floating-animation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Shimmer Effect */
.shimmer {
  position: relative;
  overflow: hidden;
}

.shimmer::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shimmer-animation 2s infinite;
}

@keyframes shimmer-animation {
  0% { left: -100%; }
  100% { left: 100%; }
}

/* Spotlight Hover Effect */
.spotlight {
  position: relative;
  overflow: hidden;
}

.spotlight::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    rgba(255, 255, 255, 0.2) 0%,
    transparent 50%
  );
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
  pointer-events: none;
}

.spotlight:hover::before {
  opacity: 1;
}

/* Magnetic Effect */
.magnetic {
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Morph Background */
.morph-bg {
  background-image: var(--gradient-background);
  background-size: 200% 200%;
  animation: morph-animation 10s ease infinite alternate;
}

@keyframes morph-animation {
  0% { background-position: 0% 0%; border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%; }
  50% { background-position: 100% 100%; border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%; }
  100% { background-position: 0% 0%; border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%; }
}

/* Card Hover Effects */
.hover-card {
  transition: all 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.dark .hover-card:hover {
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.2);
}

/* Text Selection Color */
::selection {
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

/* Improved Accessibility for Dark Mode */
.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
  color: white;
}

.dark p, .dark span, .dark li {
  color: #e2e8f0;
}

/* Rotating Animation */
.rotate-animation {
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Scale Animation on Hover */
.scale-on-hover {
  transition: transform 0.3s ease;
}

.scale-on-hover:hover {
  transform: scale(1.05);
}

/* Shake Animation */
.shake-animation {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}

/* Stripes Background Animation */
.animated-stripes {
  background-image: linear-gradient(
    -45deg,
    var(--color-primary-light) 25%,
    transparent 25%,
    transparent 50%,
    var(--color-primary-light) 50%,
    var(--color-primary-light) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  animation: stripes-animation 2s linear infinite;
}

@keyframes stripes-animation {
  0% { background-position: 0 0; }
  100% { background-position: 50px 50px; }
}

/* Blinking Animation */
.blinking {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Flip Animation */
.flip-animation {
  animation: flip 2s infinite;
  transform-style: preserve-3d;
}

@keyframes flip {
  0%, 100% { transform: rotateY(0deg); }
  50% { transform: rotateY(180deg); }
}

/* Loading Dots Animation */
.loading-dots::after {
  content: '.';
  animation: loading-dots 1.5s steps(3, end) infinite;
  display: inline-block;
  width: 0.5em;
  text-align: left;
  overflow: hidden;
  vertical-align: bottom;
}

@keyframes loading-dots {
  0%, 33% { content: '.'; }
  34%, 66% { content: '..'; }
  67%, 100% { content: '...'; }
}

/* Ripple Effect for Buttons */
.ripple-effect {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple-effect::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple-effect:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* Shadow Animation */
.shadow-pulse {
  animation: shadow-pulse 2s infinite;
}

@keyframes shadow-pulse {
  0% { box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0.4); }
  70% { box-shadow: 0 0 0 15px rgba(var(--color-primary-rgb), 0); }
  100% { box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0); }
}

/* Interactive Hover Effect */
.interactive-hover {
  position: relative;
}

.interactive-hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;
  border-radius: inherit;
}

.interactive-hover:hover::before {
  opacity: 0.1;
}

/* Moving Background Animation */
.moving-background {
  background-size: 400% 400%;
  animation: moving-background-animation 15s ease infinite;
}

@keyframes moving-background-animation {
  0% { background-position: 0% 0%; }
  25% { background-position: 100% 0%; }
  50% { background-position: 100% 100%; }
  75% { background-position: 0% 100%; }
  100% { background-position: 0% 0%; }
}

/* Bouncing Animation */
.bouncing {
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-10px); }
}

/* Typewriter Cursor Effect */
.typewriter-cursor::after {
  content: '|';
  display: inline-block;
  animation: typewriter-cursor 1s infinite;
}

@keyframes typewriter-cursor {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* Letter Spacing Animation */
.letter-spacing-animation {
  animation: letter-spacing 2s ease-in-out infinite alternate;
}

@keyframes letter-spacing {
  from { letter-spacing: normal; }
  to { letter-spacing: 0.3em; }
}

/* Wave Effect for Buttons */
.wave-effect {
  position: relative;
  overflow: hidden;
}

.wave-effect::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: radial-gradient(circle, var(--color-primary-light) 1px, transparent 1px);
  background-size: 15px 15px;
  background-position: 0 0;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;
}

.wave-effect:hover::after {
  opacity: 0.6;
  animation: wave 1s linear;
}

@keyframes wave {
  0% { transform: translateX(0) translateY(0); }
  100% { transform: translateX(20px) translateY(-20px); }
}

/* Focus-within Effect */
.focus-within-effect:focus-within {
  box-shadow: 0 0 0 2px var(--color-primary);
  transform: scale(1.02);
  transition: all 0.3s ease;
}

/* Improved Accessibility Labels */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Text Balance for Better Typography */
.text-balance {
  text-wrap: balance;
}

/* Text Pretty for Proper Punctuation */
.text-pretty {
  text-wrap: pretty;
}

/* Custom Underline Effect */
.custom-underline {
  text-decoration: none;
  background-image: linear-gradient(transparent calc(100% - 2px), var(--color-primary) 2px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.3s;
  padding-bottom: 2px;
}

.custom-underline:hover {
  background-size: 100% 100%;
}

/* Page Transition Effects */
.page-fade-in {
  animation: page-fade-in 0.5s ease-in-out;
}

@keyframes page-fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Loading Skeleton Animation */
.skeleton-loading {
  background: linear-gradient(
    90deg,
    var(--color-primary-light) 25%,
    var(--color-secondary-light) 50%,
    var(--color-primary-light) 75%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}

/* Custom Caret Color */
.custom-caret {
  caret-color: var(--color-primary);
}

/* Notch Text Decoration */
.notch-text {
  position: relative;
  display: inline-block;
}

.notch-text::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.3em;
  background-color: var(--color-primary-light);
  z-index: -1;
}

/* Ring Animation */
.ring-animation {
  animation: ring-pulse 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite alternate;
}

@keyframes ring-pulse {
  0% { box-shadow: 0 0 0 0 var(--color-primary-light); }
  100% { box-shadow: 0 0 0 10px var(--color-primary-light); }
}

/* Split Text Effect */
.split-text {
  position: relative;
  overflow: hidden;
}

.split-text span {
  display: inline-block;
  transition: transform 0.3s;
}

.split-text:hover span {
  transform: translateY(-100%);
}

.split-text span::after {
  content: attr(data-after);
  position: absolute;
  top: 100%;
  left: 0;
}

/* Rising Underline Animation */
.rising-underline {
  position: relative;
}

.rising-underline::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.rising-underline:hover::after {
  transform: scaleX(1);
}

/* Elastic Text Effect */
.elastic-text {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.elastic-text:hover {
  transform: scale(1.2);
}

/* Color Cycle Animation */
.color-cycle {
  animation: color-cycle 10s infinite;
}

@keyframes color-cycle {
  0% { color: var(--color-primary); }
  33% { color: var(--color-secondary); }
  66% { color: var(--color-accent); }
  100% { color: var(--color-primary); }
}

/* Reveal Text Effect */
.reveal-text {
  position: relative;
  color: transparent;
}

.reveal-text::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--color-primary);
  overflow: hidden;
  white-space: nowrap;
  animation: reveal 2s steps(25, end) forwards;
}

@keyframes reveal {
  0% { width: 0; }
  100% { width: 100%; }
}

/* Media Query for no Animation Preference */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}